import React, { useState, useEffect, useCallback } from "react"
import createApp from "@shopify/app-bridge"
import { Redirect } from "@shopify/app-bridge/actions"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page, PageActions, Modal, ResourceList, ResourceItem, TextStyle } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"
import { useLazyQuery, useMutation } from "react-apollo"
import { GET_DRAFT_ORDERS, GET_DRAFT_ORDER, SEND_DRAFT_ORDER } from "helpers/graphql"

import { genericNavBar } from "../helpers/navigation"

import { OrderForm } from "components/Order/OrderForm"

const config = require("../../../.runtimeconfig.json").config
const shopifyConfig = config?.shopify[process.env.NODE_ENV]

// const DEFAULT_QUERY_STRING = `tag:'onedayPAY'`
const DEFAULT_QUERY_STRING = ""

const navbarConfig = {
  ...genericNavBar,
  title: "New Plan",
}

const app = createApp({
  apiKey: shopifyConfig.api_key,
  shopOrigin: ReactifyApp.Auth.getShop(),
})

const redirect = Redirect.create(app)

const NewPlanPage = props => {
  const { location } = props
  const [pickerModalActive, setPickerModalActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [lastCursor, setLastCursor] = useState(null)
  const [showNext, setNext] = useState(false)
  const [getDraftOrders, { data: queryDraftOrdersData }] = useLazyQuery(GET_DRAFT_ORDERS, {
    variables: { first: 10, query: DEFAULT_QUERY_STRING, reverse: true },
    fetchPolicy: "no-cache",
  })
  const [updateDraftOrder, { data: mutateDraftOrderData }] = useMutation(GET_DRAFT_ORDER)
  const [sendDraftOrder, { data: sendDraftOrderData }] = useMutation(SEND_DRAFT_ORDER)
  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [hasConverted, setHasConverted] = useState(false)
  const [sendStatus, setSendStatus] = useState(null)
  const [isSending, setIsSending] = useState(false)

  const resourceName = {
    singular: "draft order",
    plural: "draft orders",
  }

  useEffect(() => {
    if (selectedOrder) {
      let isConverted = false
      for (const edge of selectedOrder.lineItems.edges) {
        const { customAttributes } = edge.node
        const tag = customAttributes.find(attr => attr.key === "_onedayPAY")
        isConverted = tag ? true : isConverted
      }
      setHasConverted(isConverted)
    }
  }, [selectedOrder])

  useEffect(() => {
    if (pickerModalActive) {
      setLoading(true)
      getDraftOrders({
        variables: { first: 10, last: null, query: DEFAULT_QUERY_STRING, afterCursor: lastCursor, reverse: true },
        fetchPolicy: "no-cache",
      })
    } else {
      setOrders([])
      setLastCursor(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerModalActive])

  useEffect(() => {
    if (showNext) {
      setLoading(true)
      getDraftOrders({
        variables: { first: 10, last: null, query: DEFAULT_QUERY_STRING, afterCursor: lastCursor, reverse: true },
        fetchPolicy: "no-cache",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNext])

  useEffect(() => {
    if (!queryDraftOrdersData) return
    const { draftOrders } = queryDraftOrdersData
    if (lastCursor) {
      setOrders(orders.concat(draftOrders.edges.map(edge => edge.node)))
    } else {
      setOrders(draftOrders.edges.map(edge => edge.node))
    }

    handleData(queryDraftOrdersData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDraftOrdersData])

  useEffect(() => {
    if (!mutateDraftOrderData) return
    setIsUpdating(false)
    const { draftOrderUpdate } = mutateDraftOrderData
    if (draftOrderUpdate.draftOrder) {
      setSelectedOrder(draftOrderUpdate.draftOrder)
    }
  }, [mutateDraftOrderData])

  useEffect(() => {
    if (!sendDraftOrderData) return
    setIsSending(false)
    const { draftOrderInvoiceSend } = sendDraftOrderData
    if (draftOrderInvoiceSend?.draftOrder?.id) {
      setSendStatus("success")
    } else {
      setSendStatus("critical")
    }
  }, [sendDraftOrderData])

  useEffect(() => {
    if (sendStatus) {
      setTimeout(() => {
        setSendStatus(null)
      }, 5000)
    }
  }, [sendStatus])

  const handlePickerModalChange = useCallback(() => setPickerModalActive(!pickerModalActive), [pickerModalActive])

  const handleCreateDraftOrder = () => {
    handleClosePickerModal()
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: "/draft_orders/new",
      newContext: true,
    })
  }

  const handleScrollBottom = () => {
    if (!loading && showNext) {
      setLoading(true)
      getDraftOrders({
        variables: { first: 10, last: null, query: DEFAULT_QUERY_STRING, afterCursor: lastCursor, reverse: true },
        fetchPolicy: "no-cache",
      })
    }
  }

  const handleUpdateDraftOrder = async (draftOrderId, lineItems, customAttributes) => {
    setIsUpdating(true)
    let input = {}
    if (lineItems) {
      input.lineItems = lineItems
    }
    if (customAttributes) {
      input.customAttributes = customAttributes
    }
    updateDraftOrder({
      variables: {
        id: draftOrderId,
        input,
      },
    })
  }

  const handleSendDraftOrder = (draftOrderId, email) => {
    setIsSending(true)
    sendDraftOrder({
      variables: {
        id: draftOrderId,
        email: {
          to: email,
        },
      },
    })
  }

  const handleData = queryDraftOrdersData => {
    const { draftOrders } = queryDraftOrdersData
    setNext(draftOrders?.pageInfo?.hasNextPage)
    setLastCursor(draftOrders?.edges && draftOrders?.edges.length ? draftOrders.edges[draftOrders.edges.length - 1].cursor : null)
    setLoading(false)
  }

  const handleOpenPickerModal = () => {
    handlePickerModalChange()
  }

  const handleClosePickerModal = () => {
    setLoading(false)
    setOrders([])
    setLastCursor(null)
    handlePickerModalChange()
  }

  const handleClickItem = item => {
    setSelectedOrder(item)
    handleClosePickerModal()
  }

  const handleUpdateLineItems = lineItems => {
    handleUpdateDraftOrder(selectedOrder.id, lineItems)
  }

  const handleUpdateAttributes = customAttributes => {
    handleUpdateDraftOrder(selectedOrder.id, null, customAttributes)
  }

  const handleSendLink = email => {
    handleSendDraftOrder(selectedOrder.id, email)
  }

  const handleConvertToPlan = () => {
    const lineItems = selectedOrder.lineItems.edges.map(({ node }) => {
      let data = {
        variantId: node.variant.id,
        quantity: node.quantity,
      }
      const hasTag = node.product?.tags?.find(tag => tag.includes("payment-plan:"))
      if (hasTag) {
        data.customAttributes = {
          key: "_onedayPAY",
          value: "true",
        }
      }
      return data
    })

    handleUpdateLineItems(lineItems)
  }

  const renderItem = item => {
    return (
      <ResourceItem key={item.id} id={item.id} onClick={() => handleClickItem(item)}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <h3>
              <TextStyle variation="strong">{item.name}</TextStyle>
            </h3>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {`$${item.totalPrice}`}
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {ReactifyApp.Utils.dateDistance(new Date(item?.updatedAt?.seconds ? item.updatedAt.seconds * 1000 : item.updatedAt))}
          </div>
        </div>
      </ResourceItem>
    )
  }

  return (
    <Page title={navbarConfig.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...navbarConfig} />

      <OrderForm
        isUpdating={isUpdating}
        redirect={redirect}
        order={selectedOrder}
        hasConverted={hasConverted}
        onOpenPickerModal={handleOpenPickerModal}
        onUpdateLineItems={handleUpdateLineItems}
        onUpdateAttributes={handleUpdateAttributes}
        onSendLink={handleSendLink}
        sendStatus={sendStatus}
        isSending={isSending}
      />

      {selectedOrder && !hasConverted ? (
        <div style={{ marginTop: "25px" }}>
          <PageActions
            primaryAction={{
              content: "Convert to plan",
              onAction: handleConvertToPlan,
              loading: isUpdating,
            }}
          />
        </div>
      ) : null}

      <Modal
        open={pickerModalActive}
        title="Select Draft Order"
        onClose={handleClosePickerModal}
        onScrolledToBottom={handleScrollBottom}
        secondaryActions={[
          {
            content: "Create Draft Order",
            onAction: handleCreateDraftOrder,
          },
        ]}
      >
        <ResourceList resourceName={resourceName} items={orders} renderItem={renderItem} loading={loading} />
      </Modal>
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(NewPlanPage)
