import React from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import { Banner, Link } from "@shopify/polaris"

export const OrderBanner = ({ redirect, order, onOpenPickerModal }) => {
  const handleCreateDraftOrder = () => {
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: "/draft_orders/new",
      newContext: true,
    })
  }

  const handleSelectOrder = event => {
    event.preventDefault()
    onOpenPickerModal()
  }

  return order ? (
    <Banner title={`Draft ${order.name} selected`} status="success">
      <Link onClick={handleSelectOrder}>{`Select another draft order`}</Link>
    </Banner>
  ) : (
    <Banner title={"Convert to new plan"} status="info" action={{ content: "Create draft order", onAction: handleCreateDraftOrder }}>
      <p>This page helpes you to convert a draft order to new plan.</p>
    </Banner>
  )
}
